export enum PATHS {
  any = '*',
  home = '/',
  profileIntegrations = '/?tab=9',
  auth = '/auth',
  applications = '/applications',
  benefits = '/benefits',
  benefitApplications = '/benefitapplications',
  departments = '/departments',
  hardwares = '/hardwares',
  editHardware = '/hardwares/:hardwareId/edit',
  reviews = '/reviews',
  positions = '/positions',
  grades = '/grades',
  /** @deprecated */
  technologyStack = '/technologyStack',
  employees = '/employees',
  employment = '/employment/:id/:token',
  activityLog = '/action',
  reviews360 = '/reviews360',
  vacancies = '/vacancies',
  vacancy = '/vacancies/:vacancyId',
  vacanciesBase = '/resumedb',
  userPage = '/user/:userIdFromUrl',
  error = '/error',
  forbiddenError = '/error/forbidden',
  statistic = '/statistic',
  settings = '/settings',
  settingsFirstTab = '/settings?tab=1',
  settingsIntegrations = '/settings?tab=6',
  createResumeFromVacancy = '/vacancies/:vacancyId/newResume',
  createResume = '/vacancies/newResume',
  editResume = '/vacancies/:vacancyId/resume/:resumeId',
  servicemode = '/servicemode',
}
