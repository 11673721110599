import { baseApi } from 'store/api/baseApi'
import { GradeTypes } from 'types/model/grade'

export type GradePostData = Pick<GradeTypes.Model, 'name'>

export type GradePatchData = Partial<GradePostData>

export const gradeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Получение списка грейдов
    getGrades: builder.query<GradeTypes.Model[], void>({
      query: () => 'grade',
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: 'Grade', id }))
          : [{ type: 'Grade', id: 'LIST' }],
    }),

    // Получение конкретного грейда по ID
    getGradeById: builder.query<GradeTypes.Model, number>({
      query: (id) => `grade/${id}`,
      providesTags: (result, error, id) => [{ type: 'Grade', id }],
    }),

    // Получение грейда по ID должности
    getGradesByPositionId: builder.query<GradeTypes.Model[], number>({
      query: (departmentId) => `grade/position/${departmentId}`,
      providesTags: (result, error, departmentId) => [
        { type: 'Grade', id: `department-${departmentId}` },
      ],
    }),

    // Создание грейда
    createGrade: builder.mutation<GradeTypes.Model, GradePostData>({
      query: (newGrade) => ({
        url: 'grade',
        method: 'POST',
        body: newGrade,
      }),
      invalidatesTags: ['Grade'],
    }),

    // Редактирование существующего грейда
    editGrade: builder.mutation<
      GradeTypes.Model,
      { id: number; data: GradePatchData }
    >({
      query: ({ id, data }) => ({
        url: `grade/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Grade', id }],
    }),

    // Удаление грейда
    deleteGrade: builder.mutation<void, number>({
      query: (id) => ({
        url: `grade/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Grade', id }],
    }),
  }),
})

export const {
  useGetGradesQuery,
  useGetGradeByIdQuery,
  useGetGradesByPositionIdQuery,
  useCreateGradeMutation,
  useEditGradeMutation,
  useDeleteGradeMutation,
} = gradeApi
