import React, {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { PATHS } from 'constants/paths'
import { AuthActionCreators } from 'store/actions/auth'
import { AuthState } from 'types/authType'
import { useSetupInterceptors } from 'hooks'
import GlobalLoader from 'components/ui/GlobalLoader'
import useLocalStorage from 'hooks/useLocalstorage'
import { useAuth } from 'hooks/useAuth'
import { ROLES } from 'constants/roles'
import checkRole from 'utils/profile/checkRole'
import useIntegrationsRedirect from 'hooks/useIntegrationsRedirect'

interface RouterContentProps {
  children: ReactNode
  roles?: ROLES[]
  isPrivate?: boolean
  path: string
}

export const RouterContent = ({
  children,
  isPrivate = false,
  path,
  roles,
}: RouterContentProps) => {
  // router logic

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const auth = useAuth()

  const [isLoading, setIsLoading] = useState(auth.isLoading)
  const [serviceMode] = useLocalStorage('service', false)
  const {
    value: storedRedirectUrls,
    setValue: setStoredRedirectUrls,
    storageKey,
  } = useIntegrationsRedirect()

  // check roles

  const isAdmin = auth.permissions.admin
  const isHr = auth.permissions.hr
  const isNotAdminOrHR = !isAdmin && !isHr

  useSetupInterceptors(auth.token)

  const loginAsync = useCallback(
    (data: AuthState) =>
      new Promise<void>((resolve) => {
        dispatch(AuthActionCreators.login(data.accessToken, data.role))
        resolve()
      }),
    [dispatch]
  )

  // Navigate to error page if user roles do not include any of passed roles (only for private routes)
  useLayoutEffect(() => {
    if (!roles || isLoading || !auth.isAuth) return

    if (isPrivate && !checkRole(auth.roles, roles)) {
      navigate(PATHS.error, { state: { message: 'Нет доступа' } })
    }
  }, [auth.isAuth, auth.roles, isLoading, isPrivate, navigate, roles])

  // Saving auth data to store
  useLayoutEffect(() => {
    if (auth && !!auth.token && !!auth.roles?.length) {
      loginAsync({
        role: auth.roles,
        accessToken: auth.token,
        isAuthorised: auth.isAuth,
      }).then(() => {
        setIsLoading(false)
      })
    } else {
      queueMicrotask(() => {
        setIsLoading(false)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token, dispatch])

  useEffect(() => {
    if (isLoading) return

    if (storedRedirectUrls) {
      const referrer = document.referrer

      const availableReferrers = ['avito.ru', 'hh.ru', 'habr.com']

      if (availableReferrers.some((ref) => referrer.includes(ref))) {
        if (storedRedirectUrls) {
          localStorage.removeItem(storageKey)
          setStoredRedirectUrls(null)
          navigate(storedRedirectUrls)
        }
      } else {
        localStorage.removeItem(storageKey)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, navigate, storageKey])

  // Redirect to auth if unauthorized
  useLayoutEffect(() => {
    if (isLoading) return

    if (isPrivate && !auth.isAuth) {
      navigate(PATHS.auth)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrivate, auth.isAuth, isLoading])

  // Reset profile page tab query for non-admin / non-HR users
  useLayoutEffect(() => {
    if (isLoading) return

    const isOnUserPage = location.pathname.includes('user')
    const hasTabQuery = location.search.includes('tab')

    if (isNotAdminOrHR && isOnUserPage && hasTabQuery) {
      navigate(location.pathname)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, isNotAdminOrHR])

  // Employment page redirect if token is invalid
  useLayoutEffect(() => {
    if (!path.startsWith(`/${PATHS.employment.split('/')[1]}`) || !params.token)
      return

    if (params.token?.length !== 32) {
      navigate(PATHS.auth)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, path])

  // Service mode redirect logic
  useLayoutEffect(() => {
    if (!isAdmin && !isLoading) {
      const isOnServiceModePage = location.pathname === PATHS.servicemode

      if (!isOnServiceModePage && serviceMode) {
        navigate(PATHS.servicemode)
      }

      if (isOnServiceModePage && !serviceMode) {
        navigate(PATHS.auth)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin])

  return <Suspense fallback={<GlobalLoader />}>{children}</Suspense>
}
