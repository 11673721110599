import React, {
  createContext,
  ElementRef,
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as ST from './styled'
import { ReactComponent as TurnOnIcon } from 'assets/icons/turnOn.svg'
import { ReactComponent as TurnOffIcon } from 'assets/icons/turnOff.svg'
import { ReactComponent as Edit } from 'assets/icons/edit.svg'
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg'
import { ReactComponent as Submit } from 'assets/icons/checkedGray.svg'
import { ReactComponent as Camera } from 'assets/icons/camera.svg'
import { noDataHelper } from 'utils/noDataHelper'
import ServiceModeSwitch from 'components/dialogs/settings/ServiceModeSwitch'
import { useGetServiceModeStatusQuery } from 'store/api/setting'
import Loader from 'components/ui/Loader'
import IconButton from 'components/ui/buttons/IconButton'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { ROLES } from 'constants/roles'
import { StaticGeneralTabContentTypes as T } from './types'
import BaseInput from 'components/ui/inputs/BaseInput'
import {
  Fields,
  Placeholders,
  ValidationSchema,
} from 'components/settings/Tabs/General/EditingGeneralTabContent/constants'
import { useFormik } from 'formik'
import { putSetting } from 'api/settings'
import { setToastError, setToastSuccess } from 'utils/handlerError'
import BaseSelect from 'components/ui/BaseSelect'
import { TimeZones } from 'constants/timeZones'
import { FileTypes } from 'constants/fileTypes'
import { postFile } from 'api/employment'
import getFileData from 'utils/file/getFileData'

const renderLogo = (path?: string): JSX.Element =>
  path ? <ST.Logo src={path} alt="Логотип" /> : <ST.DefaultLogo />

const InfoBlockContext = createContext<T.InfoBlockContextProps>({})

const LoadLogoMask = () => (
  <ST.LogoMask>
    <Camera />
  </ST.LogoMask>
)

const InfoBlock: FC<T.InfoBlockProps> = ({
  title,
  children,
  inputComponent,
  onSubmit,
  submitDisabled,
  onClose,
}) => {
  const { updateSettings, isLoading } = useContext(InfoBlockContext)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  return (
    <ST.BlockInfo>
      <ST.HeaderBlock>{title}</ST.HeaderBlock>

      <ST.RowInfoWrapper>
        {isEditing ? inputComponent : children}

        {isEditing ? (
          <ST.InfoBlockButtonWrapper>
            <ST.InfoBlockButton
              onClick={() => {
                onClose()
                setIsEditing(false)
              }}
              actionType="cancel"
            >
              <Cancel />
            </ST.InfoBlockButton>
            <ST.InfoBlockButton
              actionType="submit"
              disabled={submitDisabled}
              onClick={() => {
                onSubmit()
                  .then(() => {
                    updateSettings?.()
                    setIsEditing(false)
                    setToastSuccess('Успешно')
                  })
                  .catch(setToastError)
              }}
            >
              <Submit />
            </ST.InfoBlockButton>
          </ST.InfoBlockButtonWrapper>
        ) : (
          <IconButton
            icon={Edit}
            onClick={() => setIsEditing(true)}
            disabled={isLoading}
            noFill
          />
        )}
      </ST.RowInfoWrapper>
    </ST.BlockInfo>
  )
}

const LogoInfoBlock: FC = () => {
  const { tunes, updateSettings } = useContext(InfoBlockContext)
  const [file, setFile] = useState<File | null>(null)

  const inputRef = useRef<ElementRef<'input'>>(null)

  const imgPath = useMemo(
    () => getFileData(tunes?.logo?.img),
    [tunes?.logo?.img]
  )

  return (
    <ST.LogoWrapper>
      <ST.LogoHeaderBlock>Логотип</ST.LogoHeaderBlock>

      <ST.LogoWithButtonsWrapper>
        <ST.LogoContainer onClick={() => inputRef.current?.click()}>
          <LoadLogoMask />
          {renderLogo(file ? URL.createObjectURL(file) : imgPath)}
        </ST.LogoContainer>

        {file && (
          <ST.InfoBlockButtonWrapper>
            <ST.InfoBlockButton
              onClick={() => setFile(null)}
              actionType="cancel"
            >
              <Cancel />
            </ST.InfoBlockButton>
            <ST.InfoBlockButton
              actionType="submit"
              onClick={() => {
                postFile(file).then((res) => {
                  if (tunes?.logo?.id && res.isSuccess && res.payload?.id) {
                    putSetting(tunes.logo.id, {
                      ...tunes?.logo,
                      imgId: res.payload.id,
                    }).then(() => {
                      updateSettings?.()
                      setFile(null)
                    })
                  }
                })
              }}
            >
              <Submit />
            </ST.InfoBlockButton>
          </ST.InfoBlockButtonWrapper>
        )}
      </ST.LogoWithButtonsWrapper>

      <input
        type="file"
        ref={inputRef}
        accept={FileTypes.images}
        style={{ visibility: 'hidden' }}
        onChange={(e) => {
          const f = e.target.files?.[0]
          if (f) {
            setFile(f)
            e.target.value = ''
          }
        }}
      />
    </ST.LogoWrapper>
  )
}

const ServiceModeBlock: FC<T.ServiceModeBlockProps> = ({
  isLoading,
  serviceMode,
  onClick,
}) => (
  <ST.Service>
    <ST.ServiceWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ST.HeaderBlock>Сервисный режим *</ST.HeaderBlock>
          <ST.RowInfo>{serviceMode ? 'Включен' : 'Выключен'}</ST.RowInfo>
        </>
      )}
    </ST.ServiceWrapper>
    <ST.ServiceModeWrapper onClick={onClick}>
      <IconButton
        icon={serviceMode ? TurnOffIcon : TurnOnIcon}
        disabled={isLoading}
        title={`${serviceMode ? 'Выключить' : 'Включить'} сервисный режим`}
      />
    </ST.ServiceModeWrapper>
  </ST.Service>
)

const StaticGeneralTabContent: FC<T.Props> = ({
  tunes,
  updateSettings,
  isLoading,
}) => {
  const [isServiceModeSwitchShow, setIsServiceModeSwitchShow] = useState(false)

  const auth = useSelector((state: RootState) => state.auth)
  const isAdmin = auth?.role.includes(ROLES.admin)

  const {
    data: serviceMode,
    isLoading: isServiceModeLoading,
    isFetching: isServiceModeFetching,
  } = useGetServiceModeStatusQuery(undefined, { skip: !isAdmin })

  const openServiceModeSwitch = () => setIsServiceModeSwitchShow(true)
  const hideServiceModeSwitch = () => setIsServiceModeSwitchShow(false)

  const { values, handleChange, setFieldValue, setValues, errors } =
    useFormik<T.InfoBlockFormValues>({
      initialValues: {
        systemName: tunes?.systemName?.value ?? '',
        timeZone: tunes?.timeZone?.value ?? '',
        policyLink: tunes?.policyLink?.value ?? '',
      },
      onSubmit: () => {},
      validateOnChange: true,
      validationSchema: ValidationSchema,
    })

  const onClose = useCallback(() => {
    setValues({
      systemName: tunes?.systemName?.value ?? '',
      timeZone: tunes?.timeZone?.value ?? '',
      policyLink: tunes?.policyLink?.value ?? '',
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tunes])

  return (
    <InfoBlockContext.Provider value={{ tunes, updateSettings, isLoading }}>
      <ST.MainContainer>
        <ST.GridBlock>
          <InfoBlock
            title="Название системы"
            onClose={onClose}
            onSubmit={async () =>
              tunes?.systemName &&
              putSetting(tunes.systemName.id, {
                ...tunes.systemName,
                value: values.systemName,
              })
            }
            inputComponent={
              <BaseInput
                placeholder={Placeholders.systemName}
                id={Fields.systemName}
                name={Fields.systemName}
                value={values.systemName}
                onChange={handleChange}
              />
            }
            submitDisabled={!!errors.systemName?.length}
          >
            <ST.RowInfo title={noDataHelper(tunes?.systemName?.value)}>
              {noDataHelper(tunes?.systemName?.value)}
            </ST.RowInfo>
          </InfoBlock>

          <LogoInfoBlock />
        </ST.GridBlock>
        <ST.GridBlock>
          <InfoBlock
            title="Часовой пояс"
            onSubmit={async () =>
              tunes?.timeZone &&
              putSetting(tunes.timeZone.id, {
                ...tunes.timeZone,
                value: values.timeZone,
              })
            }
            onClose={onClose}
            inputComponent={
              <BaseSelect
                value={values.timeZone || ''}
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: '240px' }}
                placeHolder={Placeholders.timeZone}
                listItems={TimeZones}
                name={Fields.timeZone}
                typeSelect={Fields.timeZone}
                passValue={(name, value) =>
                  setFieldValue(
                    name,
                    TimeZones.find((zone) => zone.value === value)?.item
                  )
                }
              />
            }
            submitDisabled={!!errors.timeZone?.length}
          >
            <ST.RowInfo>{noDataHelper(tunes?.timeZone?.value)}</ST.RowInfo>
          </InfoBlock>
          <InfoBlock
            title="Политика конфиденциальности"
            onSubmit={async () =>
              tunes?.policyLink &&
              putSetting(tunes.policyLink.id, {
                ...tunes.policyLink,
                value: values.policyLink,
              })
            }
            onClose={onClose}
            inputComponent={
              <BaseInput
                placeholder={Placeholders.policyLink}
                id={Fields.policyLink}
                name={Fields.policyLink}
                value={values.policyLink}
                onChange={handleChange}
              />
            }
            submitDisabled={!!errors.policyLink?.length}
          >
            <ST.RowInfo style={{ textDecoration: 'underline' }}>
              {tunes?.policyLink?.value ? (
                <ST.Link href={tunes.policyLink.value} target="_blank">
                  {noDataHelper(tunes.policyLink.value)}
                </ST.Link>
              ) : (
                noDataHelper(tunes?.policyLink?.value)
              )}
            </ST.RowInfo>
          </InfoBlock>
        </ST.GridBlock>
        <ST.GridBlock>
          <ServiceModeBlock
            isLoading={isServiceModeLoading || isServiceModeFetching}
            serviceMode={serviceMode ?? false}
            onClick={openServiceModeSwitch}
          />
        </ST.GridBlock>
      </ST.MainContainer>
      <ServiceModeSwitch
        show={isServiceModeSwitchShow}
        onClose={hideServiceModeSwitch}
      />
    </InfoBlockContext.Provider>
  )
}

export default StaticGeneralTabContent
