import { ReviewConclusions, SurveyStatuses } from 'constants/reviewStatuses'
import { ReviewAnswers } from 'constants/reviewsOperationTypes'

type NameEntity = { name: string }

export namespace ReviewTypes {
  export interface Task {
    id?: number
    type: number
    text: string
    status: number
  }

  export interface Grade {
    id: number
    name: string
  }

  export interface ReviewTypeObject {
    id: number
    name: string
  }

  export interface User {
    id: number
    email: string
    surname: string
    name: string
    patronymic: string
    statusName: string
    position: {
      id: number
      name: string
    }
    userImg: {
      path: string
      url: string
    }
    salaryType: number
  }

  export type ResponsibleUser = Omit<User, 'email' | 'statusName'>

  export interface Expert {
    id: number
    email: string
    surname: string
    name: string
    patronymic: string
    statusName: string
  }

  export type Competency = {
    id: number
    name: string
    category: string
    description: string
  }

  export type Expertise = {
    id: number
    competencyId?: number
    competency?: Competency
    answer?: number
    textAnswer?: string
    number: number
  }

  export type SurveyReview = {
    id: number
    user: Omit<User, 'email' | 'statusName' | 'position'>
  }

  export type UserSurveyForReview = {
    id: number
    date: Date
    type: ReviewType
    status: ReviewStatuses
    user: Omit<User, 'email' | 'statusName' | 'position'>
  }

  export interface Survey {
    id: number
    expert: Expert
    textReview: string
    expertises: Expertise[]
    status: SurveyStatuses
    type: number
    review?: SurveyReview
    userSurveyForReview: UserSurveyForReview
  }

  export interface Model {
    id: number
    date: string
    type: ReviewType
    tasks: Task[]
    salary: number
    grade: Grade
    user: User
    responsibleUser: ResponsibleUser
    comment: string
    salaryHand: number
    surveys: Survey[]
    experts: Expert[]
    userSurvey: Survey
    status: ReviewStatuses
  }

  interface Review360User {
    id: number
    surname: string
    name: string
    position: NameEntity
    grade: NameEntity
  }

  export interface Review360Category {
    name: string
    count: number
    value: number
  }

  export interface Review360Result {
    expertiseResults: ExpertiseResult[]
    completedSurveyCount: number
    totalSurveyCount: number
    categoryValues: Review360Category[]
    textReviews: string[]
    totalValue: number
    review: {
      id: number
      date: string
      type: ReviewType
      grade: NameEntity
      user: Review360User
      status: ReviewStatuses
    }
  }

  export type Dismiss = {
    comment: string
  }

  export type ExpertiseResult = {
    number: number
    competency: string
    category: string
    userAnswer: ReviewAnswers
    conclusion: ReviewConclusions
    prevValue: number
    currentValue: number
    expertAnswers: ReviewAnswers[]
  }
}

export enum ReviewType {
  adapt = 1,
  plan = 2,
  disc = 3,
  tech = 4,
}

export enum ReviewStatuses {
  not_complete = 0,
  wait = 1,
  close = 2,
  cancel = 3,
}
