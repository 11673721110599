import { baseApi } from 'store/api/baseApi'

interface ServiceMode {
  isServiceMode: boolean
}

export const serviceModeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Получение статуса сервисного режима
    getServiceModeStatus: builder.query<boolean, void>({
      query: () => 'setting/serviceMode',
      transformResponse: (response: ServiceMode) => {
        const isServiceMode = response.isServiceMode
        localStorage.setItem('service', String(isServiceMode))

        return isServiceMode
      },
      providesTags: ['Setting', 'ServiceMode'],
    }),

    // Установка статуса сервисного режима
    setServiceModeStatus: builder.mutation<ServiceMode, boolean>({
      query: (isServiceMode) => {
        const params = new URLSearchParams()
        params.append('isServiceMode', String(isServiceMode))

        return {
          url: `setting/setServiceMode?${params.toString()}`,
          method: 'PATCH',
        }
      },
      invalidatesTags: ['Setting', 'ServiceMode'],
    }),
  }),
})

export const { useGetServiceModeStatusQuery, useSetServiceModeStatusMutation } =
  serviceModeApi
