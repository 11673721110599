export enum SettingsSections {
  GENERAL = 1,
  CONTACTS = 2,
  DOCUMENT_PATTERNS = 3,
  HIRING_STAGES = 4,
  HARDWARE_TYPES = 5,
  INTEGRATIONS = 6,
}

export enum SettingsSubsections {
  GENERAL = 1,
  SOCIAL_NETWORKS_AND_MESSENGERS = 2,
  PORTFOLIO_AND_OTHER = 3,
  EMAIL_NOTIFICATIONS = 4,
  CALENDAR = 5,
  REVIEW = 6,
  VK_TEAMS = 7,
  HHRU = 8,
  HABR = 9,
  AVITO = 11,
}

export enum SettingsKeys {
  SYSTEM_NAME = 'system_name',
  LOGO = 'logo',
  TIME_ZONE = 'time_zone',
  PRIVACY_POLICY_LINK = 'privacy_policy_link',
  SERVICE_MODE = 'service_mode',
  MAIL_SENDER = 'mail_sender',
  PORT = 'port',
  HOST = 'host',
  LOGIN = 'login',
  PASSWORD = 'password',
  CALENDAR_MAIL_SENDER = 'calendar_mail_sender',
  CALENDAR_PORT = 'calendar_mail_port',
  CALENDAR_HOST = 'calendar_mail_host',
  CALENDAR_LOGIN = 'calendar_mail_login',
  CALENDAR_PASSWORD = 'calendar_mail_password',
  ADDRESS = 'address',
  SITE_URL = 'site_url',
  PHONE = 'phone',
  EMAIL = 'email',
  SURVEY_PATTERN_EXCEL_FILE = 'survey_pattern_excel_file',
  PATTERN_TECH_SURVEY = 'pattern_tech_survey',
  PATTERN_COMMON_SURVEY = 'pattern_common_survey',
  PATTERN_SURVEY_360 = 'pattern_survey_360',
  GOOGLE_CLIENT_ID = 'google_client_id',
  GOOGLE_REDIRECT_URI = 'google_redirect_uri',
  REVIEW_CHAT_ID = 'review_chat_id',
  ADMIN_CHAT_ID = 'admin_chat_id',
  ACCOUNTANCY_CHAT_ID = 'accountancy_chat_id',
  ACCESS_CHAT_ID = 'access_chat_id',
  BOT_TOKEN = 'bot_token',
  HH_APIKEY = 'hh_apikey',
  BOT_VK_NAME = 'bot_vk_name',
  NICKNAME = 'nickname',
  MAIL_NAME = 'mail_name',
  API_HH_NAME = 'api_hh_name',
  REVIEW_NAME = 'review_name',
  CALENDAR_NAME = 'calendar_name',
  HH_REDIRECT_URI = 'hh_redirect_uri',
  HH_AUTH_LINK = 'hh_auth_link',
  GOOGLE_AUTH_LINK = 'google_auth_link',
  HH_SECRET = 'hh_secret',
  HH_CLIENT_ID = 'hh_client_id',
  GOOGLE_STATUS = 'google_status',
  HH_STATUS = 'hh_status',

  // Avito
  AVITO_NAME = 'avito_name',
  AVITO_CLIENT_ID = 'avito_client_id',
  AVITO_REDIRECT_URI = 'avito_redirect_uri',
  AVITO_SECRET = 'avito_secret',
  AVITO_AUTH_LINK = 'avito_auth_link',
  AVITO_STATUS = 'avito_status',
}

export enum ServiceMode {
  OFF = 'Выключен',
  ON = 'Включен',
}

export interface ISetting {
  id: number
  name: string
  value: string
  description: string
  user: { id: 0 } | null
  section: SettingsSections
  subsection: SettingsSubsections
  img: { id: number; path: string; name?: string; url: string } | null
  title: string
}

export type ISettingsCreateBody = Partial<ISetting>
export type ISettingsPutBody = Omit<
  Partial<ISetting>,
  'id' | 'user' | 'img'
> & {
  imgId?: number
  userId?: number
}

export type ISettingSubsection = {
  subsection: SettingsSubsections
  settings: ISetting[]
}

export interface Tunes {
  systemName: ISetting | null
  logo: ISetting | null
  timeZone: ISetting | null
  policyLink: ISetting | null
  serviceMode: ISetting | null
}

export type ServiceModeType = {
  isServiceMode: boolean
}
