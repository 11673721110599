import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BaseButton from 'components/ui/buttons/BaseButton'
import { PATHS } from 'constants/paths'
import { handlerError, setToastError } from 'utils/handlerError'
import { RequiredFields } from 'constants/requiredFields'
import { InputSizes } from 'constants/inputSizes'
import { Placeholders } from 'constants/placeholders'
import { ButtonLabels } from 'constants/buttonLabels'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import Body from 'components/ui/layout/Body'
import { useEditGradeMutation, useGetGradeByIdQuery } from 'store/api/grade'
import Loader from 'components/ui/Loader'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left.svg'

const inputName = 'name'

const EditGrade = () => {
  useTitle(PAGES.editGrade)
  const navigate = useNavigate()
  const { gradeId } = useParams()
  const {
    data: grade,
    error,
    isLoading,
  } = useGetGradeByIdQuery(Number(gradeId))

  const [editGrade] = useEditGradeMutation()

  const { handleChange, values, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: grade?.name || '',
    },
    onSubmit: (data) => {
      editGrade({ id: +gradeId!, data: { name: data.name } })
        .then(() => {
          navigate(PATHS.grades)
        })
        .catch((err) => handlerError(err))
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(RequiredFields.positionName),
    }),
  })

  useEffect(() => {
    error && setToastError(String(error))
  }, [error])

  return (
    <Body>
      <ST.EditGradeHead>
        <ST.HeadGrade>
          <IconButton icon={BackIcon} onClick={() => navigate(PATHS.grades)} />
          <ST.TitleGrade>редактирование</ST.TitleGrade>
        </ST.HeadGrade>
        <ST.Button>
          <BaseButton
            type={'submit'}
            text={ButtonLabels.save}
            typeButton={ButtonTypes.primary}
            onClick={handleSubmit}
          />
        </ST.Button>
      </ST.EditGradeHead>
      <ST.EditGradeBody>
        {isLoading ? (
          <Loader />
        ) : (
          <ST.GradeHead>
            <ST.InputWrapper>
              <ST.Label>
                Название грейда<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.Input
                placeholder={Placeholders.junior}
                sizeInput={InputSizes.small}
                id={inputName}
                name={inputName}
                value={values.name}
                onChange={handleChange}
              />
            </ST.InputWrapper>
          </ST.GradeHead>
        )}
      </ST.EditGradeBody>
    </Body>
  )
}

export default EditGrade
