import { AxiosError } from 'axios'
import { ResponseErrorMessages } from 'constants/responseErrorMessages'
import { PATHS } from 'constants/paths'
import { setupInterceptors } from 'utils/setupInterceptors'
import { apiInstance, apiInstanceForFile, apiInstanceForFileJSON } from 'api'
import { useNavigate } from 'react-router-dom'
import { useLayoutEffect } from 'react'

export const useSetupInterceptors = (token: string) => {
  const navigate = useNavigate()

  useLayoutEffect(() => {
    if (!token.length) return
    const errorHandler = (error: AxiosError) => {
      switch (error?.response?.status) {
        case 401:
          switch (error.response?.data.message) {
            case ResponseErrorMessages.ERROR_403_EXPIRED:
              navigate(PATHS.forbiddenError)
              break
            case ResponseErrorMessages.ERROR_403_REQUIRED:
              break
          }
          break
        case 404:
          switch (error.response?.data.message) {
            case ResponseErrorMessages.ERROR_404_USER_NOT_FOUND:
              navigate(PATHS.error)
              break
          }
      }
    }

    const [apiReqId, apiResId] = setupInterceptors(
      apiInstance,
      token,
      errorHandler
    )
    const [apiFileReqId, apiFileResId] = setupInterceptors(
      apiInstanceForFile,
      token,
      errorHandler
    )
    const [apiFileJSONReqId, apiFileJSONResId] = setupInterceptors(
      apiInstanceForFileJSON,
      token,
      errorHandler
    )

    return () => {
      apiInstance.interceptors.request.eject(apiReqId)
      apiInstance.interceptors.response.eject(apiResId)

      apiInstanceForFile.interceptors.request.eject(apiFileReqId)
      apiInstanceForFile.interceptors.response.eject(apiFileResId)

      apiInstanceForFileJSON.interceptors.request.eject(apiFileJSONReqId)
      apiInstanceForFileJSON.interceptors.response.eject(apiFileJSONResId)
    }
  }, [navigate, token])
}
