import React, { useEffect } from 'react'
import * as ST from './styled'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { COLORS } from 'constants/colors'
import './styled.css'
import NumberFormat from 'react-number-format'
import enGB from 'date-fns/locale/en-GB'

export interface Props {
  setStartDate: SetState<Date>
  startDate: Date
  includeDates?: [Date, Date]
  excludeDates?: [Date, Date]
  minDate?: Date
  maxDate?: Date
  small?: boolean
  disabled?: boolean
}

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

// TODO moment.js
const CalendarInput = ({
  setStartDate,
  startDate,
  includeDates,
  excludeDates,
  minDate,
  maxDate,
  small,
  disabled,
}: Props) => {
  useEffect(() => {
    registerLocale('en-GB', enGB)
  }, [])

  const formatDate = (date: Date): void => {
    const newDate = date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    const parts: string[] = newDate.split('/')
    setStartDate(new Date(+parts[2], +parts[1] - 1, +parts[0]))
  }

  return (
    <ST.Wrapper disabled={!!disabled}>
      <DatePicker
        className={'header'}
        dateFormat="dd.MM.yyyy"
        disabled={!!disabled}
        customInput={
          <NumberFormat
            customInput={!small ? ST.WideInput : ST.Input}
            format="##.##.####"
          />
        }
        includeDates={includeDates}
        excludeDates={excludeDates}
        minDate={minDate}
        maxDate={maxDate}
        id={'dateBorn'}
        locale={'en-GB'}
        name={'dateBorn'}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          increaseYear,
          decreaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 5,
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: `${COLORS.white}`,
            }}
          >
            <ST.ButtonContainer>
              <ST.ButtonBack
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              />
              <ST.HeaderText>{months[date.getMonth()]}</ST.HeaderText>
              <ST.ButtonForward
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
              />
            </ST.ButtonContainer>
            <ST.ButtonContainer>
              <ST.ButtonBack
                onClick={decreaseYear}
                disabled={prevMonthButtonDisabled}
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
              />
              <ST.HeaderText>{date.getFullYear()}</ST.HeaderText>
              <ST.ButtonForward
                onClick={increaseYear}
                disabled={nextMonthButtonDisabled}
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
              />
            </ST.ButtonContainer>
          </div>
        )}
        selected={startDate}
        onChange={(e: Date) => {
          formatDate(e)
        }}
      />
    </ST.Wrapper>
  )
}
export default CalendarInput
