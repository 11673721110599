import React, { FC, useEffect, useMemo, useState } from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import * as ST from './styled'
import debounce from 'lodash/debounce'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material'
import { getEmployees } from 'api/employees'
import { parseDataToEmployees } from 'utils/parseData'
import { handlerError } from 'utils/handlerError'
import {
  listReviewStatuses,
  listReviewTypes,
} from 'constants/reviewStatusesDescription'
import { BreakPoints } from 'constants/breakPoints'
import { SelectsBlockUtils as U } from './utils'
import { ReviewApiTypes as T } from 'api/review/types'
import { UserTypes } from 'types/model/user'
import { USERS_FILTERS } from 'components/reviews/index'

interface Props {
  userList: UserTypes.Model[]
  setValueFilter: (obj: T.IReviewsValuesFilter) => void
  valuesFilter: T.IReviewsValuesFilter
  clearInput: boolean
}

const SelectsBlock: FC<Props> = ({
  userList,
  setValueFilter,
  valuesFilter,
  clearInput,
}) => {
  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )
  const isTabletMiddleDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_MIDDLE})`
  )

  const userFromFilters = useMemo<Nullable<IItem<number>>>(() => {
    const user = userList.find((u) => u.id === valuesFilter.user)

    return user
      ? { item: `${user.surname} ${user.name}`, value: user.id }
      : null
  }, [userList, valuesFilter.user])

  const [activeSelect, setActiveSelect] = useState<string | null>(null)
  const [selectedUser, setSelectedUser] =
    useState<Nullable<IItem<number>>>(userFromFilters)
  const [filterUserList, setFilterUserList] = useState<IItem<number>[]>([])

  const clearInputProps = clearInput ? { value: '' } : {}

  useEffect(() => {
    clearInput && setSelectedUser(null)
  }, [clearInput])

  const passValue = (type: string, currentValue: string | null): void => {
    setValueFilter({
      ...valuesFilter,
      [type as keyof T.IReviewsValuesFilter]: currentValue,
    })
  }

  // TODO RTK
  const handleInputChange = (value: string) => {
    getEmployees(1, USERS_FILTERS, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  useEffect(() => {
    setFilterUserList(parseDataToEmployees(userList))
  }, [userList])

  return (
    <ST.SelectsBlock>
      <Autocomplete
        key={selectedUser?.value}
        disablePortal
        id="combo-box-demo"
        clearOnBlur={false}
        blurOnSelect
        noOptionsText={'Нет доступных вариантов'}
        options={filterUserList}
        style={U.getStyle(isMobileDevice, isTabletMiddleDevice)}
        sx={U.getSx(isMobileDevice)}
        filterSelectedOptions
        value={selectedUser}
        onInputChange={debounce(
          (e) => handleInputChange(e.target?.value),
          1000
        )}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option?.item}
        onChange={(event, value) => {
          passValue('user', value ? String(value.value) : null)
          setSelectedUser(value)
        }}
        renderOption={(props, option) => (
          <Box
            {...props}
            component="li"
            style={{
              backgroundColor: '#fefefe',
              border: 'none',
              color: '#898989',
            }}
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            key={option.value}
          >
            {option.item}
          </Box>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            style={{
              backgroundColor: '#f8f8f8',
              borderWidth: 0,
              '::placeholder': {
                opacity: '0.5',
              },
              borderRadius: '10px',
            }}
            label="Сотрудник"
          />
        )}
      />
      <BaseSelect
        {...clearInputProps}
        required
        value={
          listReviewStatuses.find((s) => s.value === valuesFilter.status)
            ?.item ?? ''
        }
        placeHolder={'Статус'}
        listItems={listReviewStatuses}
        typeSelect={'status'}
        passValue={passValue}
        setActive={setActiveSelect}
        activeSelect={clearInput ? '' : activeSelect}
      />
      <BaseSelect
        {...clearInputProps}
        required
        value={
          listReviewTypes.find((t) => t.value === valuesFilter.type)?.item ?? ''
        }
        placeHolder={'Тип'}
        listItems={listReviewTypes}
        typeSelect={'type'}
        passValue={passValue}
        setActive={setActiveSelect}
        activeSelect={clearInput ? '' : activeSelect}
      />
    </ST.SelectsBlock>
  )
}

export default SelectsBlock
