import React, { FC } from 'react'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'

interface Props {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const DeleteButton: FC<Props> = ({ disabled, onClick }) => (
  <IconButton icon={DeleteIcon} disabled={disabled} onClick={onClick} />
)

export default DeleteButton
