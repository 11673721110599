import React, { lazy, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { RouterContent } from './RouterContent'
import { PATHS } from 'constants/paths'
import Layout from 'components/Layouts/layout'
import Department from 'components/departments/Department'
import EditDepartment from 'components/departments/EditDepartment'
import Position from 'components/positions/Position'
import EditPosition from 'components/positions/EditPosition'
import EditGrade from 'components/grades/EditGrade'
import EditHardware from 'components/hardwares/EditHardware'
import BenefitApplications from 'components/benefitApplications'
import EditBenefitApplication from 'components/benefitApplications/EditBenefitApplication'
import Reviews from 'components/reviews'
import Statistic from 'components/vacanciesStatistic'
import Settings from 'components/settings'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { MenuData } from 'components/Header/Menu/data'
import { ROLES } from 'constants/roles'

const PathRoles = {
  ...MenuData.MenuItemsByRoles,
  [PATHS.editHardware]: [ROLES.sysadmin, ROLES.admin],
}

const Error = lazy(() =>
  import('pages/errorPage').then((module) => ({ default: module.ErrorPage }))
)

const ForbiddenPage = lazy(() =>
  import('pages/forbiddenPage').then((module) => ({
    default: module.ForbiddenPage,
  }))
)

const Employees = lazy(() =>
  import('pages/employeesPage').then((module) => ({
    default: module.EmployeesPage,
  }))
)
const Profile = lazy(() =>
  import('pages/profilePage').then((module) => ({
    default: module.ProfilePage,
  }))
)

const Auth = lazy(() =>
  import('pages/authPage').then((module) => ({ default: module.AuthPage }))
)
const Employment = lazy(() =>
  import('pages/employmentPage').then((module) => ({
    default: module.EmploymentPage,
  }))
)

const Departments = lazy(() =>
  import('pages/departmentsPage').then((module) => ({
    default: module.DepartmentsPage,
  }))
)

const Positions = lazy(() =>
  import('pages/positionsPage').then((module) => ({
    default: module.PositionsPage,
  }))
)

const ReviewList = lazy(() =>
  import('pages/reviewsPage').then((module) => ({
    default: module.ReviewsPage,
  }))
)

const ReviewCommon = lazy(() =>
  import('pages/reviewsCommonPage').then((module) => ({
    default: module.ReviewCommonPage,
  }))
)

const Reviews360 = lazy(() =>
  import('pages/reviews360Page').then((module) => ({
    default: module.Reviews360Page,
  }))
)

const Grades = lazy(() =>
  import('pages/gradesPage').then((module) => ({ default: module.GradesPage }))
)

const TechnologyStacks = lazy(() =>
  import('pages/technologyStacksPage').then((module) => ({
    default: module.TechnologyStacksPage,
  }))
)

const Applications = lazy(() =>
  import('pages/applicationsPage').then((module) => ({
    default: module.ApplicationsPage,
  }))
)

const Hardwares = lazy(() =>
  import('pages/hardwaresPage').then((module) => ({
    default: module.HardwaresPage,
  }))
)

const ActivityLog = lazy(() =>
  import('pages/activityLogPage').then((module) => ({
    default: module.ActivityLogPage,
  }))
)

const Result = lazy(() =>
  import('pages/resultPage').then((module) => ({
    default: module.ResultPage,
  }))
)

const Result360 = lazy(() =>
  import('pages/result360Page').then((module) => ({
    default: module.Result360Page,
  }))
)

const Vacancies = lazy(() =>
  import('pages/vacanciesPage').then((module) => ({
    default: module.VacanciesPage,
  }))
)

const Vacancy = lazy(() =>
  import('pages/vacancyPage').then((module) => ({
    default: module.VacancyPage,
  }))
)

const CreateResume = lazy(() =>
  import('pages/newCandidatePage').then((module) => ({
    default: module.NewCandidatePage,
  }))
)

const ServiceMode = lazy(() =>
  import('pages/serviceModePage').then((module) => ({
    default: module.ServiceModePage,
  }))
)

export const Routing = () => {
  const [isActiveTab, setIsActiveTab] = useState(false)

  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  return (
    <>
      {
        <Routes>
          <Route
            path={PATHS.auth}
            element={<RouterContent children={<Auth />} path={PATHS.auth} />}
          />
          <Route
            path={`${PATHS.employment}`}
            element={
              <RouterContent
                children={<Employment />}
                path={PATHS.employment}
              />
            }
          />
          <Route
            path={PATHS.employees}
            element={
              <RouterContent
                roles={PathRoles[PATHS.employees]}
                isPrivate
                children={
                  <Layout
                    children={<Employees />}
                    withHeader={!isMobileDevice}
                  />
                }
                path={PATHS.employees}
              />
            }
          />
          <Route
            path={PATHS.home}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.home]}
                children={
                  <Layout children={<Profile isActiveTab={isActiveTab} />} />
                }
                path={PATHS.home}
              />
            }
          >
            <Route
              path={PATHS.userPage}
              element={
                <RouterContent
                  isPrivate
                  children={
                    <Layout children={<Profile isActiveTab={isActiveTab} />} />
                  }
                  path={PATHS.home}
                />
              }
            />
          </Route>
          <Route
            path={PATHS.departments}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.departments]}
                children={<Layout children={<Departments />} />}
                path={PATHS.departments}
              />
            }
          />
          <Route
            path={PATHS.reviews}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.reviews]}
                children={<Layout children={<ReviewList />} />}
                path={PATHS.reviews}
              />
            }
          />
          <Route
            path={PATHS.vacancies}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.vacancies]}
                children={<Layout children={<Vacancies />} />}
                path={PATHS.vacancies}
              />
            }
          />
          <Route
            path={PATHS.vacancy}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.vacancies]}
                children={<Layout children={<Vacancy />} />}
                path={PATHS.vacancy}
              />
            }
          />
          <Route
            path={`${PATHS.departments}/:departmentId`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.departments]}
                children={<Layout children={<Department />} />}
                path={PATHS.departments}
              />
            }
          />
          <Route
            path={`${PATHS.departments}/:departmentId/edit`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.departments]}
                children={<Layout children={<EditDepartment />} />}
                path={PATHS.departments}
              />
            }
          />
          <Route
            path={PATHS.positions}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.positions]}
                children={<Layout children={<Positions />} />}
                path={PATHS.positions}
              />
            }
          />
          <Route
            path={`${PATHS.positions}/:positionId`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.positions]}
                children={<Layout children={<Position />} />}
                path={PATHS.positions}
              />
            }
          />
          <Route
            path={`${PATHS.positions}/:positionId/edit`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.positions]}
                children={<Layout children={<EditPosition />} />}
                path={PATHS.positions}
              />
            }
          />
          <Route
            path={PATHS.grades}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.grades]}
                children={<Layout children={<Grades />} />}
                path={PATHS.grades}
              />
            }
          />
          <Route
            path={`${PATHS.grades}/:gradeId/edit`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.grades]}
                children={<Layout children={<EditGrade />} />}
                path={PATHS.grades}
              />
            }
          />
          <Route
            path={PATHS.hardwares}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.hardwares]}
                children={<Layout children={<Hardwares />} />}
                path={PATHS.hardwares}
              />
            }
          />
          <Route
            path={PATHS.editHardware}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.editHardware]}
                children={<Layout children={<EditHardware />} />}
                path={PATHS.editHardware}
              />
            }
          />
          <Route
            path={PATHS.benefitApplications}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.benefitApplications]}
                children={<Layout children={<BenefitApplications />} />}
                path={PATHS.benefitApplications}
              />
            }
          />
          <Route
            path={`${PATHS.benefitApplications}/:benefitApplicationId/edit`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.benefitApplications]}
                children={<Layout children={<EditBenefitApplication />} />}
                path={PATHS.benefitApplications}
              />
            }
          />
          <Route
            path={PATHS.reviews}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.reviews]}
                children={<Layout children={<Reviews />} />}
                path={PATHS.reviews}
              />
            }
          />
          <Route
            path={`${PATHS.reviews}/survey/:surveyId`}
            element={
              <RouterContent
                isPrivate
                children={
                  <Layout
                    title={'Общий опрос к ревью'}
                    titleAttribute={'title'}
                    children={<ReviewCommon />}
                  />
                }
                path={PATHS.reviews}
              />
            }
          />
          <Route
            path={`${PATHS.reviews}/survey360/:surveyId`}
            element={
              <RouterContent
                isPrivate
                children={
                  <Layout
                    title={'Опрос 360'}
                    titleAttribute={'title'}
                    children={<Reviews360 />}
                  />
                }
                path={PATHS.reviews}
              />
            }
          />
          <Route
            path={`${PATHS.reviews}/survey/results/:surveyId`}
            element={
              <RouterContent
                isPrivate
                children={
                  <Layout
                    title={'Общий опрос к ревью'}
                    titleAttribute={'title'}
                    children={
                      <Result
                        setIsActiveTab={setIsActiveTab}
                        isActiveTab={isActiveTab}
                      />
                    }
                  />
                }
                path={PATHS.reviews}
              />
            }
          />
          <Route
            path={`${PATHS.reviews}/survey360/results/:surveyId`}
            element={
              <RouterContent
                isPrivate
                children={
                  <Layout
                    children={
                      <Result360
                        setIsActiveTab={setIsActiveTab}
                        isActiveTab={isActiveTab}
                      />
                    }
                  />
                }
                path={PATHS.reviews}
              />
            }
          />
          <Route
            path={`${PATHS.createResumeFromVacancy}`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.vacancies]}
                children={<Layout children={<CreateResume />} />}
                path={PATHS.vacancies}
              />
            }
          />
          <Route
            path={`${PATHS.createResume}`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.vacancies]}
                children={<Layout children={<CreateResume />} />}
                path={PATHS.vacancies}
              />
            }
          />
          <Route
            path={`${PATHS.editResume}`}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.vacancies]}
                children={<Layout children={<CreateResume isEdit />} />}
                path={PATHS.vacancies}
              />
            }
          />
          <Route
            path={PATHS.applications}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.applications]}
                children={<Layout children={<Applications />} />}
                path={PATHS.applications}
              />
            }
          />
          <Route
            path={PATHS.activityLog}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.activityLog]}
                children={<Layout children={<ActivityLog />} />}
                path={PATHS.activityLog}
              />
            }
          />
          <Route
            path={PATHS.any}
            element={<RouterContent children={<Error />} path={PATHS.any} />}
          />
          <Route
            path={PATHS.error}
            element={<RouterContent children={<Error />} path={PATHS.error} />}
          />
          <Route
            path={PATHS.forbiddenError}
            element={
              <RouterContent
                isPrivate
                children={<ForbiddenPage />}
                path={PATHS.forbiddenError}
              />
            }
          />

          <Route
            path={PATHS.statistic}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.vacancies]}
                children={<Layout children={<Statistic />} />}
                path={PATHS.statistic}
              />
            }
          />
          <Route
            path={PATHS.settings}
            element={
              <RouterContent
                isPrivate
                roles={PathRoles[PATHS.settings]}
                children={<Layout children={<Settings />} />}
                path={PATHS.settings}
              />
            }
          />
          <Route
            path={PATHS.servicemode}
            element={
              <RouterContent
                children={<Layout children={<ServiceMode />} />}
                path={PATHS.servicemode}
              />
            }
          />
        </Routes>
      }
    </>
  )
}
