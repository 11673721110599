import styled, { css } from 'styled-components'
import { COLORS } from 'constants/colors'
import { ReactComponent as LogoIcon } from 'assets/icons/logoHeader.svg'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1180px;
  max-width: 1180px;
`

export const Logo = styled(LogoIcon)`
  width: 28px;
  min-width: 28px;
  height: 28px;
`

export const UserPhoto = styled.img`
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
`

interface ILogoWrapperProps {
  isVisible: boolean
  isCenter?: boolean
}

export const LogoWrapper = styled.div<ILogoWrapperProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  ${({ isVisible, isCenter }) =>
    isVisible
      ? css`
          ${isCenter
            ? css`
                left: 50%;
                transform: translate(-50%, 0);
              `
            : css`
                transform: translateY(0);
              `}
          position: relative;
          width: auto;
        `
      : css`
          transform: translateY(-50px);
          position: absolute;
          width: 0;
        `}
  transition: 0.3s ease-in-out;
`

export const LogoTitle = styled.h1`
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
`

export const LogoUserName = styled.h1`
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HeaderMobileContainer = styled.div`
  display: flex;
  padding: 0 20px;
  min-height: 42px;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.white};
  position: sticky;
  top: 0;
  z-index: 997;
  gap: 16px;
`

HeaderMobileContainer.defaultProps = {
  // @ts-ignore
  'data-testid': 'header',
}

export interface IIcon {
  imageSrc?: string
  isOwn?: boolean
}

export const LinkIcon = styled.div<IIcon>`
  width: 24px;
  height: 24px;
  display: flex;
  -webkit-mask-image: url(${({ imageSrc }) => (imageSrc ? imageSrc : null)});
  mask-image: url(${({ imageSrc }) => (imageSrc ? imageSrc : null)});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  background-color: ${COLORS.black};
  transform: rotate(180deg);
  cursor: pointer;
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 30px;
`

export const NotificationButton = styled.button`
  background: ${COLORS.yellow};
  border-radius: 50px;
  width: 48px;
  height: 48px;
  min-width: 48px;

  &:hover {
    background: ${COLORS.yellowHover};
  }

  &:active {
    background: ${COLORS.yellowHover};
  }
`

export const BurgerButton = styled.button`
  background: ${COLORS.yellow};
  border-radius: 50px;
  width: 48px;
  height: 48px;
  min-width: 48px;

  &:hover {
    background: ${COLORS.yellowHover};
  }

  &:active {
    background: ${COLORS.yellowHover};
  }
`

export const MenuContainer = styled.div``

export const HasNotification = styled.div`
  position: absolute;
  top: 33px;
`
