import { combineReducers } from '@reduxjs/toolkit'
import { authReducer as auth } from 'store/reducers/authReducer'
import { userIdReducer as userId } from 'store/reducers/userIdReducer'
import { formReducer as form } from 'store/reducers/formReducer'
import { userInfoReducer as userInfo } from 'store/reducers/userInfoReducer'

import { baseApi } from 'store/api/baseApi'

export const rootReducer = combineReducers({
  auth,
  userId,
  form,
  userInfo,
  // rtk
  [baseApi.reducerPath]: baseApi.reducer,
})
