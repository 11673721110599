import React, { FC } from 'react'
import * as ST from './styled'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { IntegrationSteps } from 'constants/settingsSteps'
import { getSettingByKey } from 'utils/parseData'
import { ISetting, SettingsKeys } from 'types/model/settings'
import PasswordViewer from 'components/ui/labels/PasswordViewer'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as ApprovedIcon } from 'assets/icons/Applications/statuses/approved.svg'
import { IItem } from 'components/ui/BaseSelect'
import getFileData from 'utils/file/getFileData'
import useIntegrationsRedirect from 'hooks/useIntegrationsRedirect'
import { PATHS } from 'constants/paths'
import BaseButton from 'components/ui/buttons/BaseButton'

interface IUserProps {
  handleSelectEdit: (step: IntegrationSteps) => void
  mailData: ISetting[]
  calendarData: ISetting[]
  reviewData: ISetting[]
  vkData: ISetting[]
  hhData: ISetting[]
  habrData: { id: string }
  avitoData: ISetting[]
  posToSheet: Array<IItem[]>
}

type EditButtonProps = {
  step: IntegrationSteps
}

const DeleteButton = () => <IconButton icon={DeleteIcon} disabled />

// TODO refactor
const StaticIntegrationsTabContent: FC<IUserProps> = ({
  handleSelectEdit,
  mailData,
  calendarData,
  reviewData,
  vkData,
  hhData,
  avitoData,
  posToSheet,
}) => {
  const { handleClick } = useIntegrationsRedirect(PATHS.settingsIntegrations)
  const EditButton: FC<EditButtonProps> = ({ step }) => (
    <IconButton icon={EditIcon} onClick={() => handleSelectEdit(step)} />
  )

  const surveyPatternExcel = getSettingByKey(
    reviewData,
    SettingsKeys.SURVEY_PATTERN_EXCEL_FILE
  )

  return (
    <>
      <ST.GridBlock>
        <ST.InfoBlock style={{ width: '100%' }}>
          <ST.TopBlock>
            <ST.HeaderBlock>
              {getSettingByKey(mailData, SettingsKeys.MAIL_NAME)?.value}
            </ST.HeaderBlock>
            <ST.TopBlockIcons>
              <EditButton step={IntegrationSteps.MAIL} />
              <DeleteButton />
            </ST.TopBlockIcons>
          </ST.TopBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Email отправки</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(mailData, SettingsKeys.MAIL_SENDER)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Порт</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(mailData, SettingsKeys.PORT)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Хост</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(mailData, SettingsKeys.HOST)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Логин</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(mailData, SettingsKeys.LOGIN)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Пароль</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(mailData, SettingsKeys.PASSWORD)?.value && (
                <PasswordViewer
                  password={
                    getSettingByKey(mailData, SettingsKeys.PASSWORD)?.value
                  }
                />
              )}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
        </ST.InfoBlock>
      </ST.GridBlock>
      <ST.GridBlock>
        <ST.InfoBlock style={{ width: '100%' }}>
          <ST.TopBlock>
            <ST.HeaderBlock>
              {getSettingByKey(calendarData, SettingsKeys.CALENDAR_NAME)?.value}
            </ST.HeaderBlock>
            <ST.TopBlockIcons>
              <EditButton step={IntegrationSteps.CALENDAR} />
              <DeleteButton />
            </ST.TopBlockIcons>
          </ST.TopBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Email отправки</ST.TitleAttribute>
            <ST.ValueAttribute>
              {
                getSettingByKey(calendarData, SettingsKeys.CALENDAR_MAIL_SENDER)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Порт</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(calendarData, SettingsKeys.CALENDAR_PORT)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Хост</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(calendarData, SettingsKeys.CALENDAR_HOST)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Логин</ST.TitleAttribute>
            <ST.ValueAttribute>
              {
                getSettingByKey(calendarData, SettingsKeys.CALENDAR_LOGIN)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Пароль</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(calendarData, SettingsKeys.CALENDAR_PASSWORD)
                ?.value && (
                <PasswordViewer
                  password={
                    getSettingByKey(
                      calendarData,
                      SettingsKeys.CALENDAR_PASSWORD
                    )?.value
                  }
                />
              )}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
        </ST.InfoBlock>
      </ST.GridBlock>
      <ST.GridBlock>
        <ST.InfoBlock style={{ width: '100%' }}>
          <ST.TopBlock>
            <ST.HeaderBlock>
              {getSettingByKey(reviewData, SettingsKeys.REVIEW_NAME)?.value}
            </ST.HeaderBlock>
            <ST.TopBlockIcons>
              {getSettingByKey(reviewData, SettingsKeys.GOOGLE_STATUS)
                ?.value === 'true' ? (
                <ST.SecondaryButton>
                  <a
                    href={
                      getSettingByKey(reviewData, SettingsKeys.GOOGLE_AUTH_LINK)
                        ?.value ?? ''
                    }
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Сменить профиль
                    <ApprovedIcon style={{ marginLeft: '10px' }} />
                  </a>
                </ST.SecondaryButton>
              ) : (
                <BaseButton
                  href={
                    getSettingByKey(reviewData, SettingsKeys.GOOGLE_AUTH_LINK)
                      ?.value ?? ''
                  }
                  width={220}
                  height={40}
                  target={'_blank'}
                  text="Авторизоваться"
                />
              )}
              <EditButton step={IntegrationSteps.REVIEWS} />
              <DeleteButton />
            </ST.TopBlockIcons>
          </ST.TopBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Ключ</ST.TitleAttribute>
            <ST.ValueAttribute>
              {
                getSettingByKey(reviewData, SettingsKeys.GOOGLE_CLIENT_ID)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Секретный ключ</ST.TitleAttribute>
            <ST.ValueAttribute>
              {
                getSettingByKey(reviewData, SettingsKeys.GOOGLE_REDIRECT_URI)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.SubHeaderBlock>Документы</ST.SubHeaderBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Опрос 360</ST.TitleAttribute>
            <ST.ValueAttribute>
              {
                getSettingByKey(reviewData, SettingsKeys.PATTERN_SURVEY_360)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Общий опрос сотрудника</ST.TitleAttribute>
            <ST.ValueAttribute>
              {' '}
              {
                getSettingByKey(reviewData, SettingsKeys.PATTERN_COMMON_SURVEY)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Техническая анкета:</ST.TitleAttribute>
            <ST.ValueAttribute>
              {
                getSettingByKey(reviewData, SettingsKeys.PATTERN_TECH_SURVEY)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.SubHeaderBlock>
            Соответствие листов и должностей
          </ST.SubHeaderBlock>
          {posToSheet?.map((position) => (
            <ST.GridInfoBlock key={position[0].item}>
              <ST.TitleAttribute>{position[0].item}</ST.TitleAttribute>
              <ST.ValueAttribute>{position[1].item}</ST.ValueAttribute>
            </ST.GridInfoBlock>
          ))}

          <ST.SubHeaderBlock title={surveyPatternExcel?.description}>
            Шаблон опросов
          </ST.SubHeaderBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Опросы для ревью</ST.TitleAttribute>
            {getFileData(surveyPatternExcel?.img) ? (
              <ST.LinkAttribute
                href={getFileData(surveyPatternExcel?.img)}
                target="_blank"
              >
                Опрос для сотрудников
              </ST.LinkAttribute>
            ) : (
              <ST.ValueAttribute>Нет данных</ST.ValueAttribute>
            )}
          </ST.GridInfoBlock>
        </ST.InfoBlock>
      </ST.GridBlock>

      <ST.GridBlock>
        <ST.InfoBlock style={{ width: '100%' }}>
          <ST.TopBlock>
            <ST.HeaderBlock>
              {getSettingByKey(vkData, SettingsKeys.BOT_VK_NAME)?.value}
            </ST.HeaderBlock>
            <ST.TopBlockIcons>
              <EditButton step={IntegrationSteps.VK_BOT} />
              <DeleteButton />
            </ST.TopBlockIcons>
          </ST.TopBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>
              Идентификатор чата администраторов
            </ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(vkData, SettingsKeys.ADMIN_CHAT_ID)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>
              Идентификатор чата по бухгалтерии
            </ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(vkData, SettingsKeys.ACCOUNTANCY_CHAT_ID)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>
              Идентификатор чата по доступам
            </ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(vkData, SettingsKeys.ACCESS_CHAT_ID)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Идентификатор чата по ревью</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(vkData, SettingsKeys.REVIEW_CHAT_ID)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Ник</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(vkData, SettingsKeys.NICKNAME)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
        </ST.InfoBlock>
      </ST.GridBlock>

      <ST.GridBlock>
        <ST.InfoBlock style={{ width: '100%' }}>
          <ST.TopBlock>
            <ST.HeaderBlock>
              {getSettingByKey(avitoData, SettingsKeys.AVITO_NAME)?.value}
            </ST.HeaderBlock>
            <ST.TopBlockIcons>
              {getSettingByKey(avitoData, SettingsKeys.AVITO_STATUS)?.value ===
              'true' ? (
                <ST.SecondaryButton>
                  <a
                    href={
                      getSettingByKey(avitoData, SettingsKeys.AVITO_AUTH_LINK)
                        ?.value ?? ''
                    }
                    target={'_blank'}
                    onClick={handleClick}
                    rel="noreferrer"
                  >
                    Сменить профиль
                    <ApprovedIcon style={{ marginLeft: '10px' }} />
                  </a>
                </ST.SecondaryButton>
              ) : (
                <BaseButton
                  href={
                    getSettingByKey(avitoData, SettingsKeys.AVITO_AUTH_LINK)
                      ?.value ?? ''
                  }
                  onClick={handleClick}
                  width={220}
                  height={40}
                  target={'_blank'}
                  text="Авторизоваться"
                />
              )}

              <EditButton step={IntegrationSteps.AVITO} />
              <DeleteButton />
            </ST.TopBlockIcons>
          </ST.TopBlock>

          <ST.GridInfoBlock>
            <ST.TitleAttribute>
              {getSettingByKey(avitoData, SettingsKeys.AVITO_CLIENT_ID)?.title}
            </ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(avitoData, SettingsKeys.AVITO_CLIENT_ID)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>
              {getSettingByKey(avitoData, SettingsKeys.AVITO_SECRET)?.title}
            </ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(avitoData, SettingsKeys.AVITO_SECRET)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>
              {
                getSettingByKey(avitoData, SettingsKeys.AVITO_REDIRECT_URI)
                  ?.title
              }
            </ST.TitleAttribute>
            <ST.ValueAttribute>
              {
                getSettingByKey(avitoData, SettingsKeys.AVITO_REDIRECT_URI)
                  ?.value
              }
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
        </ST.InfoBlock>
      </ST.GridBlock>

      <ST.GridBlock>
        <ST.InfoBlock style={{ width: '100%' }}>
          <ST.TopBlock>
            <ST.HeaderBlock>
              {getSettingByKey(hhData, SettingsKeys.API_HH_NAME)?.value}
            </ST.HeaderBlock>
            <ST.TopBlockIcons>
              {getSettingByKey(hhData, SettingsKeys.HH_STATUS)?.value ===
              'true' ? (
                <ST.SecondaryButton>
                  <a
                    href={
                      getSettingByKey(hhData, SettingsKeys.HH_AUTH_LINK)
                        ?.value ?? ''
                    }
                    onClick={handleClick}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Сменить профиль
                    <ApprovedIcon style={{ marginLeft: '10px' }} />
                  </a>
                </ST.SecondaryButton>
              ) : (
                <BaseButton
                  href={
                    getSettingByKey(hhData, SettingsKeys.HH_AUTH_LINK)?.value ??
                    ''
                  }
                  onClick={handleClick}
                  width={220}
                  height={40}
                  target={'_blank'}
                  text="Авторизоваться"
                />
              )}
              <EditButton step={IntegrationSteps.API_HH} />
              <DeleteButton />
            </ST.TopBlockIcons>
          </ST.TopBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Ключ:</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(hhData, SettingsKeys.HH_APIKEY)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Client Secret:</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(hhData, SettingsKeys.HH_SECRET)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Client ID:</ST.TitleAttribute>
            <ST.ValueAttribute>
              {getSettingByKey(hhData, SettingsKeys.HH_CLIENT_ID)?.value}
            </ST.ValueAttribute>
          </ST.GridInfoBlock>
        </ST.InfoBlock>
      </ST.GridBlock>
      <ST.GridBlock>
        <ST.InfoBlock style={{ width: '100%' }}>
          <ST.TopBlock>
            <ST.HeaderBlock>API career.habr.com</ST.HeaderBlock>
            <ST.TopBlockIcons>
              <ST.Button disabled>Авторизоваться</ST.Button>
              <EditButton step={IntegrationSteps.API_HABR} />
              <DeleteButton />
            </ST.TopBlockIcons>
          </ST.TopBlock>
          <ST.GridInfoBlock>
            <ST.TitleAttribute>Ключ</ST.TitleAttribute>
            <ST.ValueAttribute>{''}</ST.ValueAttribute>
          </ST.GridInfoBlock>
        </ST.InfoBlock>
      </ST.GridBlock>
    </>
  )
}

export default StaticIntegrationsTabContent
