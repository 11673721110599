import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import * as ST from './styled'
import { IntegrationSteps, SettingsSteps } from 'constants/settingsSteps'
import { getIntegrationSettings, getSheetLists } from 'api/settings'
import StaticIntegrationsTabContent from './StaticIntegrationsTabContent'
import EditingIntegrationsTabContent from './EditingIntegrationsTabContent'
import { ISetting, SettingsSubsections } from 'types/model/settings'
import { getPositions } from 'api/position'
import { IItem } from 'components/ui/BaseSelect'
import { setToastError } from 'utils/handlerError'
import { PositionTypes } from 'types/model/position'

interface IUserProps {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  step: SettingsSteps
}

const HABR_API_DATA = {
  id: 'mail_no_reply@profsoft.pro',
}

const IntegrationsTab: FC<IUserProps> = ({ setIsEditing, isEditing, step }) => {
  const handleIsEditing = () => {
    setIsEditing((prevState) => !prevState)
    updateSteps()
  }
  const [positions, setPositions] = useState<IItem[]>([])
  const [sheetLists, setSheetLists] = useState<IItem[]>([])
  const [positionsToSheet, setPositionsToSheets] = useState<Array<IItem[]>>([])
  const [calendarData, setCalendarData] = useState<ISetting[]>([])
  const [mailData, setMailData] = useState<ISetting[]>([])
  const [reviewData, setReviewData] = useState<ISetting[]>([])
  const [vkData, setVKData] = useState<ISetting[]>([])
  const [hhData, setHHData] = useState<ISetting[]>([])
  const [avitoData, setAvitoData] = useState<ISetting[]>([])
  const [selectedEdit, setSelectedEdit] = useState<IntegrationSteps | null>(
    null
  )
  const handleSelectedEdit = (num: IntegrationSteps) => {
    setSelectedEdit(num)
    setIsEditing((prevState) => !prevState)
  }
  const updateSteps = () => {
    getIntegrationSettings().then((resp) => {
      setMailData(
        resp.find(
          (s) => s.subsection === SettingsSubsections.EMAIL_NOTIFICATIONS
        )?.settings ?? []
      )
      setCalendarData(
        resp.find((s) => s.subsection === SettingsSubsections.CALENDAR)
          ?.settings ?? []
      )
      setReviewData(
        resp.find((s) => s.subsection === SettingsSubsections.REVIEW)
          ?.settings ?? []
      )
      setVKData(
        resp.find((s) => s.subsection === SettingsSubsections.VK_TEAMS)
          ?.settings ?? []
      )
      setHHData(
        resp.find((s) => s.subsection === SettingsSubsections.HHRU)?.settings ??
          []
      )
      setAvitoData(
        resp.find((s) => s.subsection === SettingsSubsections.AVITO)
          ?.settings ?? []
      )
    })
  }

  useEffect(() => {
    updateSteps()
  }, [step])

  const updateSheets = (
    respPositions: PositionTypes.Model[],
    sheets: IItem[]
  ) => {
    const newData: IItem[][] = []

    respPositions.forEach((position) => {
      let pos = { item: position.name, value: position.id }
      let sheet = sheets.find((list) => list.item === position.patternSheet)
      let arr: IItem[] = []

      if (pos && sheet) {
        arr = [pos, sheet]
        newData.push(arr)
      }
    })

    if (newData.length) {
      setPositionsToSheets(newData)
    }
  }

  useEffect(() => {
    !isEditing &&
      Promise.all([getSheetLists(), getPositions()])
        .then(([respSheet, respPositions]) => {
          const sheets = respSheet.map((list, index) => ({
            item: list,
            value: index + 1,
          }))

          setSheetLists(sheets)

          setPositions(
            respPositions.map((position) => ({
              item: position.name,
              value: position.id,
            }))
          )

          updateSheets(respPositions, sheets)
        })
        .catch(setToastError)
  }, [isEditing])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>Интеграции</ST.EditingHeader>
          </ST.BackBlock>
        </ST.ManageBlock>
      )}
      {!!calendarData.length &&
        !!mailData.length &&
        !!reviewData.length &&
        !!vkData.length &&
        !!hhData.length &&
        !!avitoData.length &&
        !!sheetLists.length &&
        !!positions.length &&
        (selectedEdit && isEditing ? (
          <EditingIntegrationsTabContent
            handleIsEditing={handleIsEditing}
            updateSteps={updateSteps}
            selectedEdit={selectedEdit}
            handleSelectEdit={setSelectedEdit}
            mailData={mailData}
            calendarData={calendarData}
            reviewData={reviewData}
            vkData={vkData}
            hhData={hhData}
            habrData={HABR_API_DATA}
            avitoData={avitoData}
            pos={positions}
            sheets={sheetLists}
            posToSheet={positionsToSheet ?? []}
          />
        ) : (
          <StaticIntegrationsTabContent
            handleSelectEdit={handleSelectedEdit}
            mailData={mailData}
            calendarData={calendarData}
            reviewData={reviewData}
            vkData={vkData}
            hhData={hhData}
            habrData={HABR_API_DATA}
            avitoData={avitoData}
            posToSheet={positionsToSheet}
          />
        ))}
    </ST.MainContainer>
  )
}

export default IntegrationsTab
