import { Action } from 'redux'
import { ROLES } from 'constants/roles'

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  UPDATE = 'UPDATE',
}

export type AuthState = {
  accessToken: string
  role: ROLES[]
  isAuthorised: boolean
}

interface Login extends Action {
  type: AuthActionTypes.LOGIN
  payload: { accessToken: string; role: ROLES[] }
}

interface Logout extends Action {
  type: AuthActionTypes.LOGOUT
}

interface Update extends Action {
  type: AuthActionTypes.UPDATE
  payload?: Partial<AuthState>
}

export type AuthAction = Login | Logout | Update

export enum UserIdActionTypes {
  SETUSER = 'SETUSER',
  RESETUSER = 'RESETUSER',
}

export type UserIdState = {
  userId: string
}

interface SetUser extends Action {
  type: UserIdActionTypes.SETUSER
  payload: { userId?: string }
}

interface ResetUser extends Action {
  type: UserIdActionTypes.RESETUSER
}

export type UserIdAction = SetUser | ResetUser
