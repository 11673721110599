import React, { useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { handlerError } from 'utils/handlerError'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import CreateBenefitApplication from 'components/dialogs/CreateBenefitApplication'
import { getBenefitApplications } from 'api/benefitsApplications'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import SelectsBlock from 'components/benefitApplications/SelectsBlock'
import { getEmployees } from 'api/employees'
import BenefitApplicationCard from './BenefitApplicationCard'
import { ButtonTypes } from 'constants/buttonTypes'
import { useSetupInterceptors } from 'hooks'
import { BenefitTypes } from 'types/model/benefit'
import { getBenefits } from 'api/benefits'
import { getUserCardBenefitWallet } from 'api/userPage'
import InfiniteScroll from 'react-infinite-scroll-component'
import Body from 'components/ui/layout/Body'
import Loader from 'components/ui/Loader'
import { ErrorMessages } from 'constants/errorMessages'
import { UserTypes } from 'types/model/user'
import { useModalWindowState } from 'hooks/useModalWindowState'
import EmptyResult from 'components/views/feedback/EmptyResult'
import BaseButton from 'components/ui/buttons/BaseButton'
import { UserStatuses } from 'constants/userStatuses'
import { BreakPoints } from 'constants/breakPoints'
import { useMediaQuery } from '@mui/material'
import { BenefitStatuses } from 'constants/benefitStatuses'
import { useAuth } from 'hooks/useAuth'
import EmptyResultStrings from 'constants/EmptyResult'

const DEFAULT_PAGE = 1

const BenefitApplications = () => {
  useTitle(PAGES.benefitApplication)
  const isTablet = useMediaQuery(`max-width: ${BreakPoints.TABLETS}`)

  const { userId, token } = useAuth()

  const { modalOpen, toggleModal } = useModalWindowState()
  useSetupInterceptors(token)

  const [benefitApplicationsList, setbenefitApplicationsList] = useState<
    BenefitApplicationTypes.Model[]
  >([])
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [valuesFilter, setValueFilter] =
    useState<BenefitApplicationTypes.Filter>({
      status: null,
      operationType: null,
      targetUserId: null,
    })
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [usersData, setUsersData] = useState<Nullable<UserTypes.Model[]>>(null)
  const [benefitData, setBenefitData] = useState<BenefitTypes.Model[] | null>(
    null
  )
  const [benefitWallet, setBenefitWallet] =
    useState<UserTypes.BenefitWallet | null>(null)
  const [clearInput, setClearInput] = useState<boolean>(false)
  const [noMoreApplications, setNoMoreApplications] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const updateBenefitApplicationsList = (withLoader = false) => {
    withLoader && setIsLoading(true)

    getBenefitApplications(1, valuesFilter)
      .then((data) => {
        setbenefitApplicationsList(data.data)
        setPage((p) => p + 1)
        setClearInput(false)
      })
      .catch((err) => handlerError(err))
      .finally(() => {
        withLoader && setIsLoading(false)
      })
  }

  useEffect(() => {
    if (userId) {
      getUserCardBenefitWallet(userId).then((res) => {
        setBenefitWallet(res)
      })
    }
  }, [userId])

  const loadBenefitsApplicationsInfinite = () => {
    getBenefitApplications(page, valuesFilter)
      .then((resp) => {
        setPage(resp.currentPageNumber)
        if (!!resp.data.length) {
          const benefitApplications = [...benefitApplicationsList]
          benefitApplications.push(...resp.data)
          setbenefitApplicationsList(benefitApplications)
          if (resp.currentPageNumber === resp.pageCount) {
            setNoMoreApplications(true)
          } else {
            setPage((p) => p + 1)
          }
        } else {
          setbenefitApplicationsList(resp.data)
          setNoMoreApplications(true)
        }
      })
      .catch((err) => handlerError(err))
  }

  useEffect(() => {
    updateBenefitApplicationsList(true)

    getBenefits({ status: BenefitStatuses.active })
      .then((resp) => setBenefitData(resp.data))
      .catch((err) => handlerError(err))
    getEmployees(null, { status: [UserStatuses.active] })
      .then((resp) => setUsersData(resp.data))
      .catch((err) => handlerError(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLDivElement).id === 'filter') {
      setOpenFilter((p) => !p)
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)
    getBenefitApplications(1, valuesFilter)
      .then((resp) => {
        setNoMoreApplications(
          resp.data.length ? resp.currentPageNumber === resp.pageCount : true
        )
        setbenefitApplicationsList(resp.data)
      })
      .catch((err) => handlerError(err))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const filtersButtonsDisabled = useMemo(
    () => !Object.values(valuesFilter)?.filter((v) => !!v).length,
    [valuesFilter]
  )

  return (
    <>
      <Body>
        <ST.DepartmentsHead>
          <ST.TitleContainer>
            <ST.DepartmentsTitle>Бенефиты</ST.DepartmentsTitle>
          </ST.TitleContainer>

          <ST.Button width={'196px'} height={'48px'} onClick={toggleModal}>
            Подать заявку
          </ST.Button>
        </ST.DepartmentsHead>
        <ST.FilterBlock
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            handleChange(event)
          }
          id={'filter'}
        >
          <ST.FilterTitle isOpen={openFilter} id={'filter'}>
            Фильтры
          </ST.FilterTitle>
          <ST.FilterList isOpen={openFilter} id={'filter'}>
            {usersData?.length && (
              <SelectsBlock
                userList={usersData}
                setValueFilter={setValueFilter}
                valuesFilter={valuesFilter}
                clearInput={clearInput}
              />
            )}
            <ST.ButtonsBlock>
              <BaseButton
                width={'160px'}
                height={'40px'}
                onClick={handleSubmit}
                text="Применить"
                disabled={filtersButtonsDisabled}
              />
              <BaseButton
                typeButton={ButtonTypes.secondary}
                width={isTablet ? 140 : 160}
                height={'40px'}
                text="Сбросить"
                disabled={filtersButtonsDisabled}
                onClick={() => {
                  setClearInput(true)
                  updateBenefitApplicationsList(true)
                  setValueFilter({
                    status: null,
                    operationType: null,
                    targetUserId: null,
                  })
                }}
              />
            </ST.ButtonsBlock>
          </ST.FilterList>
        </ST.FilterBlock>

        <ST.ApplicationsBody>
          {isLoading ? (
            <Loader marginTop="60px" />
          ) : benefitApplicationsList?.length ? (
            <ST.ApplicationsBlock>
              <InfiniteScroll
                dataLength={benefitApplicationsList.length}
                next={loadBenefitsApplicationsInfinite}
                hasMore={!noMoreApplications}
                loader={<Loader marginTop="40px" />}
                endMessage={ErrorMessages.endLoading}
                style={{
                  height: '100%',
                  overflow: 'initial',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                {usersData?.length &&
                  benefitData?.length &&
                  benefitApplicationsList.map((item) => (
                    <BenefitApplicationCard
                      onClose={updateBenefitApplicationsList}
                      key={item.id}
                      benefitApplicationInfo={item}
                      userList={usersData}
                      benefitList={benefitData}
                    />
                  ))}
              </InfiniteScroll>
            </ST.ApplicationsBlock>
          ) : (
            <ST.NotFoundContainer>
              <EmptyResult />
            </ST.NotFoundContainer>
          )}
        </ST.ApplicationsBody>
      </Body>
      {userId && usersData?.length && benefitData?.length && (
        <CreateBenefitApplication
          show={modalOpen}
          onClose={toggleModal}
          onSuccess={updateBenefitApplicationsList}
          userId={userId}
          userData={usersData}
          benefitData={benefitData}
          benefitWallet={benefitWallet?.balance ?? 0}
        />
      )}
    </>
  )
}

export default BenefitApplications
